<template>
    <div class="StaffCreate">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="80" align="center" />
                <el-table-column label="员工类型" width="220">
                    <template slot-scope="scope">
                        <el-radio-group v-model.number="scope.row.types">
                            <el-radio :label="1">业务人员</el-radio>
                            <el-radio :label="0">管理人员</el-radio>
                        </el-radio-group>
                    </template>
                </el-table-column>
                <el-table-column label="用户名" width="160">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.userNames" size="small" placeholder="8到20位字母或数字" />
                    </template>
                </el-table-column>
                <el-table-column label="姓名" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.realNames" size="small" placeholder="2到10位汉字" />
                    </template>
                </el-table-column>
                <el-table-column label="性别" width="140">
                    <template slot-scope="scope">
                        <el-radio-group v-model.number="scope.row.sexes">
                            <el-radio :label="0">男</el-radio>
                            <el-radio :label="1">女</el-radio>
                        </el-radio-group>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" width="140">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.mobiles" size="small" />
                    </template>
                </el-table-column>
                <el-table-column label="年龄" width="120">
                    <template slot-scope="scope">
                        <el-input v-model.number="scope.row.ages" size="small" />
                    </template>
                </el-table-column>
                <el-table-column label="身份证号" width="200">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.idNumbers" size="small" />
                    </template>
                </el-table-column>
                <el-table-column label="部门类型" width="280">
                    <template slot-scope="scope">
                        <el-radio-group v-model.number="scope.row.deptTypes">
                            <el-radio :label="3">门店</el-radio>
                            <el-radio :label="2">配送中心</el-radio>
                            <el-radio v-if="$store.state.session.code == 'TRSSTF00000000000001'" :label="1"
                                >总部</el-radio
                            >
                        </el-radio-group>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="75">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'StaffCreate',
    data() {
        return {
            tableData: [],
            url: {
                save: '/system/staff/createExtend/',
            },
        };
    },

    methods: {
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
        handleSave() {
            for (let i = 0; i < this.tableData.length; i++) {
                if (!this.validLine(this.tableData[i], i + 1)) {
                    return;
                }
            }
            const _p = {
                types: this.tableData.map((r) => r.types),
                userNames: this.tableData.map((r) => r.userNames),
                realNames: this.tableData.map((r) => r.realNames),
                sexes: this.tableData.map((r) => r.sexes),
                ages: this.tableData.map((r) => r.ages),
                mobiles: this.tableData.map((r) => r.mobiles),
                idNumbers: this.tableData.map((r) => r.idNumbers),
                deptTypes: this.tableData.map((r) => r.deptTypes),
            };
            UrlUtils.PostRemote(this, this.url.save, _p);
        },
        handleAdd() {
            this.tableData = this.tableData.concat({
                userNames: '',
                realNames: '',
                mobiles: '',
                idNumbers: '',
                ages: null,
                types: 0,
                sexes: 1,
                deptTypes: 3,
            });
        },
        validLine(ln, idx) {
            if (ln.userNames.trim().length < 2 || ln.userNames.trim().length > 20) {
                this.$message.error('第' + idx + '条数据' + '请输入正确的用户名');
                return false;
            }
            if (!window.ef.someRegs.userName.test(ln.userNames)) {
                this.$message.error('第' + idx + '条数据' + '请输入正确的用户名');
                return false;
            }
            if (ln.realNames.trim().length < 2 || ln.realNames.trim().length > 10) {
                this.$message.error('第' + idx + '条数据' + '请输入正确的姓名');
                return false;
            }
            if (!window.ef.someRegs.realName.test(ln.realNames)) {
                this.$message.error('第' + idx + '条数据' + '请输入正确的姓名');
                return false;
            }
            if (!window.ef.someRegs.mobile.test(ln.mobiles)) {
                this.$message.error('第' + idx + '条数据' + '请输入正确的手机号');
                return false;
            }
            if (ln.ages < 18 || ln.ages > 99) {
                this.$message.error('第' + idx + '条数据' + '请输入合法的年龄');
                return false;
            }
            if (!window.ef.valid.idNumber(ln.idNumbers, true)) {
                this.$message.error('第' + idx + '条数据' + '请输入合法的身份证号');
                return false;
            }
            return true;
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
